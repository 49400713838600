@tailwind base;
@tailwind components;
@tailwind utilities;

/* --------------- Buttons ------------------ */
button[role='switch'][aria-checked='false'],
button.ant-switch{
  background-color: #B3BAC3 !important;
}
button[role='switch'][aria-checked='true'],
button[role='switch'].same-style,
.ant-btn.ant-btn-primary{
  background-color: #007DBC !important;
}

/* --------------- Inputs --------------------- */
input[type='search']:focus{
  --tw-ring-shadow: 0;
}
.ant-select-selection-search{
  margin: 0 !important;
}